import Swiper from 'swiper';
import { Navigation, Pagination, FreeMode } from 'swiper/modules';
window.Swiper = Swiper; // Assign Swiper to the window object to make it globally accessible
Swiper.use([Navigation, Pagination, FreeMode]); // So that swiper can use pagination - without it we cannot use

document.addEventListener("DOMContentLoaded", () => {
  setUpSwiper();

  let widgetDropdown = document.getElementById("selected-widget-category");
  let widgetDropdownContent = document.getElementById("widget-dropdown-content");

  widgetDropdown.onclick = () => {
    widgetDropdown.classList.toggle("open");
    widgetDropdown.classList.contains("open") ? slideDownContent(widgetDropdownContent) : slideUpContent(widgetDropdownContent);
  };

  document.addEventListener("click", (event) => {
    if (!widgetDropdown.contains(event.target) && widgetDropdown.classList.contains("open")) {
      slideUpContent(widgetDropdownContent);
      widgetDropdown.classList.remove("open");
    }
  });

  
    let widgetFilters = document.querySelectorAll(".change-widget-filter");
  
    if (widgetFilters) {
      widgetFilters.forEach(filter => {
        filter.addEventListener("click", function (event) {
          let filterValue = event.target.dataset.filter;
  
          $.ajax({
            url: "/get_deal_widget_data",
            method: "GET",
            dataType: "script",
            headers: {
              "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
            },
            data: { timeframe: filterValue },
            success: function (response) {
              setUpSwiper();
              let newFilter = event.target.closest(".change-widget-filter");
              let filterText = newFilter.textContent.trim();
              let selectedWidgetCategory = document.getElementById("selected-widget-category");
              selectedWidgetCategory.querySelector("span").innerHTML = `${filterText}`;
              widgetFilters.forEach(f => f.classList.remove("!text-primary", "text-primary-text-light", "dark:text-primary-text-dark"));
              newFilter.classList.add("!text-primary", "text-primary-text-light", "dark:text-primary-text-dark");
              let widgetDropdownContent = document.getElementById("widget-dropdown-content");
              widgetDropdownContent.classList.add("hidden");
            },
            error: function (error) {
              console.error("Failed to fetch data:", error);
            },
          });
        });
      });
    }
    
});

function setUpSwiper() {
  let swiperHomepageWidget = new Swiper('#widget-scroll-container', {
    slidesPerView: 1.2,
    spaceBetween: 10,
    pagination: false,
    freeMode: true,
    freeModeSticky: false,
    breakpoints: {
      470: {
      slidesPerView: 2,
        freeMode: true,
        freeModeSticky: false,
      },
      769: {
        slidesPerView: 3,
        freeMode: true,
        freeModeSticky: false,
      },
      1280: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        freeMode: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    },
  });
  swiperHomepageWidget.update();
  swiperHomepageWidget.slideTo(0);
}
